import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import DrawerAppBar from "./pages/Navbar";
import "./App.css";
import ErrorBoundary from "./errorBoundry/ErrorBoundry";

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
      letterSpacing: "0.05em",
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 600,
      letterSpacing: "0.03em",
      color: "#f1c22e",
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500,
      letterSpacing: "0.02em",
      color: "#f1c22e",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: "0.01em",
      color: "#f1c22e",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 300,
      lineHeight: 1.6,
    },
  },
});

function App() {
  return (
    <div className="App">
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <DrawerAppBar />
          </ErrorBoundary>
        </ThemeProvider>
    </div>
  );
}

export default App;
