import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import googleIcon from "../assests/googleIcon.webp";
import { Facebook, Instagram } from "@mui/icons-material";
import { validateForm } from "./functions/formvalidation.js";
import formImageSmall from "../assests/rb_35019_small.webp";
import formImageMedium from "../assests/rb_35019_medium.webp";
import formImageLarge from "../assests/rb_35019_large.webp";

const ContactUsPage = () => {
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    eventInquiry: "",
    message: "",
  });
  const [formError, setFormErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    eventInquiry: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form (if needed)
    const errors = validateForm(formDetails);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setLoading(true);
    // Send form data to the backend (trigger Nodemailer)
    try {
      const response = await fetch("/.netlify/functions/sendEmail", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formDetails),
      });

      if (response.ok) {
        alert(
          "Form submitted successfully! Check your email for confirmation."
        );
        setLoading(false);
        setFormDetails({
          name: "",
          email: "",
          phoneNumber: "",
          eventInquiry: "",
          message: "",
        });
      } else {
        alert("Error submitting the form. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting the form. Please try again.");
      setLoading(false);
    }
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setFormDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnBlur = (e) => {
    const { name, value } = e.target;
    const fieldErrors = validateForm({ [name]: value });
    setFormErrors((prev) => ({
      ...prev,
      [name]: fieldErrors[name] || "",
    }));
  };

  return (
    <Box sx={{ width: "100%", margin: "0 auto", mt: 5 }}>
      {/* Contact Information Section */}
      <Typography
        variant="h3"
        sx={{ mb: 3, textAlign: "center" }}
        className="heading-color"
      >
        Contact Us
      </Typography>
      <Grid container spacing={4}>
        <Grid
          item
          container
          spacing={2}
          display="flex"
          justifyContent={{ md: "space-around" }}
        >
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src={formImageSmall}
              srcSet={`${formImageSmall} 600w, ${formImageMedium} 1200w, ${formImageLarge} 1920w`}
              sizes="(max-width: 600px) 600px, 
               (max-width: 1200px) 1200px, 
               1920px"
              alt="formImage"
              loading="lazy"
              style={{ width: "100%", height: "100%", borderRadius: "8px" }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ padding: 3 }}>
              <Typography variant="h4" sx={{ mb: 2 }} className="heading-color">
                Contact Form
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  name="name"
                  label="Name"
                  variant="outlined"
                  sx={{ mb: 2 }}
                  required
                  onBlur={handleOnBlur}
                  onChange={handleOnChange}
                  error={formError.name}
                  helperText={formError.name}
                  size="small"
                />
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  type="email"
                  variant="outlined"
                  sx={{ mb: 2 }}
                  required
                  onBlur={handleOnBlur}
                  onChange={handleOnChange}
                  error={formError.email}
                  helperText={formError.email}
                  size="small"
                />
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  type="tel"
                  variant="outlined"
                  required
                  sx={{ mb: 2 }}
                  onBlur={handleOnBlur}
                  onChange={handleOnChange}
                  error={formError.phoneNumber}
                  helperText={formError.phoneNumber}
                  size="small"
                />
                <TextField
                  fullWidth
                  name="eventInquiry"
                  label="Event Inquiry"
                  variant="outlined"
                  sx={{ mb: 2 }}
                  onBlur={handleOnBlur}
                  onChange={handleOnChange}
                  error={formError.eventInquiry}
                  helperText={formError.eventInquiry}
                  size="small"
                />
                <TextField
                  fullWidth
                  name="message"
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={4}
                  sx={{ mb: 2 }}
                  required
                  onBlur={handleOnBlur}
                  onChange={handleOnChange}
                  error={formError.message}
                  helperText={formError.message}
                  size="small"
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#2d4583", color: "#fff" }}
                >
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: "#fff" }} />
                  ) : (
                    "Get In Touch"
                  )}
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>

        <Grid
          item
          container
          spacing={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            container
            xs={12}
            gap={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Grid item xs={12} md={5.5}>
              <Typography variant="h4" sx={{ mb: 2 }} className="heading-color">
                Contact Information
              </Typography>
              <Paper elevation={2} sx={{ padding: 3, height: 350 }}>
                <Typography
                  variant="body1"
                  sx={{ mb: 1 }}
                  className="text-color"
                  textAlign="left"
                >
                  <strong>Venue Address:</strong> Panchasheel Sports Club
                  15/21/1/1, behind Shivsrishti Road, Bhintade Nagar, Janaki
                  Nagar, Raghav Nagar, Ambegaon Budruk, Pune, Maharashtra 411046
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mb: 1 }}
                  className="text-color"
                  textAlign="left"
                >
                  <strong>Phone (For Booking and Enquiry):</strong> 9272101216,
                  8484077176
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mb: 1 }}
                  className="text-color"
                  textAlign="left"
                >
                  <strong>Phone (Complaints):</strong> 7276666985
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mb: 1 }}
                  className="text-color"
                  textAlign="left"
                >
                  <strong>Email:</strong> panchasheelsportsclub@gmail.com
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mb: 2 }}
                  className="text-color"
                  textAlign="left"
                >
                  <Box
                    sx={{
                      mt: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                    }}
                    className="text-color"
                  >
                    <strong>Follow Us:</strong>

                    <a
                      href="https://www.facebook.com/people/Panchasheel-Sports-Club/100091458591085/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        marginRight: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Facebook /> Facebook
                    </a>
                    <a
                      href="https://www.instagram.com/panchasheel_sports_club/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        marginRight: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Instagram /> Instagram
                    </a>
                    <a
                      href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0LM42LUqxrMoyYLRSNagwTko2sjRNMzM0NzdMMU8ytAIKmRomplkYJ6capBglJiWmeokXJOYlZyQWZ6Sm5igUF-QXlRQrJOeUJgEAm_YY4Q&q=panchasheel+sports+club&rlz=1C5CHFA_enIN949IN949&oq=panchasheel&gs_lcrp=EgZjaHJvbWUqFQgCEC4YJxivARjHARiABBiKBRiOBTIGCAAQRRg5MhEIARBFGCcYOxjjAhiABBiKBTIVCAIQLhgnGK8BGMcBGIAEGIoFGI4FMgkIAxAAGAoYgAQyDwgEEC4YChivARjHARiABDIPCAUQLhgKGK8BGMcBGIAEMgkIBhAAGAoYgAQyCQgHEAAYChiABDIPCAgQLhgKGK8BGMcBGIAEMgwICRAAGAoYsQMYgATSAQkzNjkyajBqMTWoAgiwAgE&sourceid=chrome&ie=UTF-8"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={googleIcon}
                        alt="Google Icon"
                        style={{ width: "20px", borderRadius: "100%" }}
                      />{" "}
                      Google
                    </a>
                  </Box>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={5.5}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  variant="h4"
                  sx={{ mb: 2, textAlign: "center" }}
                  className="heading-color"
                >
                  Find Us Here
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "400px",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <iframe
                    title="Panchasheel_Sports_Club"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6878.969259372199!2d73.83562338410636!3d18.456600432965995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc295f61771d7b1%3A0x351af83ce0d2abae!2sPanchasheel%20Sports%20Club!5e1!3m2!1sen!2sin!4v1732198596904!5m2!1sen!2sin"
                    width="100%"
                    height="350"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUsPage;
