import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import "./facilites.css";

const facilitiesData = {
  features: [
    {
      title: "FIFA Turf",
      description:
        "FIFA-approved 50mm turf grass with industrial-grade rubber cushioning.",
    },
    {
      title: "Anti-Injury Mats",
      description: "Anti-ligament injury cushioned 30mm mats.",
    },
    {
      title: "Jogging Track",
      description: "200-meter jogging track surrounding the premises.",
    },
    {
      title: "Private Parking",
      description: "5000 sq ft of private parking space.",
    },
    {
      title: "GST Billing",
      description: "GST bills provided for bookings for taxation purposes.",
    },
    {
      title: "Accessories",
      description:
        "Available: bats, balls, stumps, footballs, goalkeeper gloves, bibs, rackets, and shuttles.",
    },
    {
      title: "Changing Rooms",
      description: "Separate changing rooms and washrooms for men and women.",
    },
    {
      title: "Surveillance",
      description: "24x7 surveillance system for your safety.",
    },
    {
      title: "Relaxation Seating",
      description: "Separate seating for relaxing and waiting between games.",
    },
    {
      title: "Easy Rescheduling",
      description: "Hassle-free rescheduling options.",
    },
    {
      title: "Refreshments",
      description: "Energy Drinks and Mineral water available on-premises.",
    },
    {
      title: "Special Discounts",
      description:
        "Special rates during happy hours and discounts for students with ID cards.",
    },
  ],
};

const FacilitiesPage = () => {
  return (
    <Box sx={{ width: "100%", margin: "0 auto", mt: 5 }}>
      {/* Header */}
      <Typography
        variant="h3"
        sx={{mb: 3, textAlign: "center" }}
      >
        Venue Features
      </Typography>

      {/* Features Grid */}
      <Grid container spacing={3}>
        {facilitiesData.features.map((feature, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper
              elevation={2}
              sx={{ padding: 2, borderRadius: 2,  }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold" }}
                className="text-color"
              >
                {feature.title}
              </Typography>
              <Typography variant="body1" className="text-color">
                {feature.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Why Choose Us Section */}
      <Box sx={{ mt: 5 }}>
        <Typography
          variant="h4"
          sx={{ mb: 3, textAlign: "center" }}
        >
          Why We Stand Out{" "}
        </Typography>
        <Box
          component="ul"
          sx={{
            pl: 2,
            textAlign: "start",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          <Typography component="li" className="text-color" paragraph>
            <strong>FIFA Turf:</strong> Enjoy professional-grade turf for
            cricket and football events.
          </Typography>
          <Typography component="li" className="text-color" paragraph>
            <strong>Modern Facilities:</strong> Experience world-class
            infrastructure with safety and convenience in mind.
          </Typography>
          <Typography component="li" className="text-color" paragraph>
            <strong>Community Focus:</strong> Special discounts and support for
            local teams, schools, and students.
          </Typography>
          <Typography component="li" className="text-color" paragraph>
            <strong>Seamless Experience:</strong> Hassle-free booking,
            rescheduling, and on-site support.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FacilitiesPage;
