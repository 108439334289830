import React from "react";
import googleIcon from "../assests/googleIcon.webp";
import { Facebook, Instagram } from "@mui/icons-material";
import { Box, Typography, Grid, Link, IconButton } from "@mui/material";
import { NAV_ITEMS } from "../constants/constant";

const Footer = () => {
  // Split NAV_ITEMS into two columns
  const half = Math.ceil(NAV_ITEMS.length / 2);
  const firstColumn = NAV_ITEMS.slice(0, half);
  const secondColumn = NAV_ITEMS.slice(half);

  return (
    <Box
      sx={{
        backgroundColor: "#2d4583",
        color: "white",
        py: 4,
        px: { xs: 2, md: 8 },
        width: "100%",
        borderRadius: "10px",
      }}
    >
      <Grid container spacing={3}>
        {/* Quick Links Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            Quick Links
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {firstColumn.map((item, index) => (
                <Link
                  key={index}
                  href={`#${item.replace(/\s+/g, "").toLowerCase()}`}
                  underline="none"
                  color="inherit"
                  sx={{
                    display: "block",
                    mb: 1,
                    "&:hover": { color: "#f1c22e" },
                  }}
                >
                  {item}
                </Link>
              ))}
            </Grid>
            <Grid item xs={6}>
              {secondColumn.map((item, index) => (
                <Link
                  key={index}
                  href={`#${item.replace(/\s+/g, "").toLowerCase()}`}
                  underline="none"
                  color="inherit"
                  sx={{
                    display: "block",
                    mb: 1,
                    "&:hover": { color: "#f1c22e" },
                  }}
                >
                  {item}
                </Link>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* Social Media Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            Follow Us
          </Typography>
          <Box>
            <IconButton
              href="https://www.facebook.com/people/Panchasheel-Sports-Club/100091458591085/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "white",
                mr: 1,
                "&:hover": { color: "#3b5998" },
              }}
            >
              <Facebook />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/panchasheel_sports_club/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white", mr: 1, "&:hover": { color: "#E4405F" } }}
            >
              <Instagram />
            </IconButton>
            <IconButton
              href="https://www.google.com/search?q=panchasheel+sports+club"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white", "&:hover": { color: "#1DA1F2" } }}
            >
              <img
                src={googleIcon}
                alt="Google Icon"
                style={{ width: "20px", borderRadius: "100%" }}
              />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {/* Copyright Section */}
      <Box
        sx={{
          textAlign: "center",
          mt: 4,
          borderTop: "1px solid #ddd",
          pt: 2,
          color: "white",
        }}
      >
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} Panchasheel Sports Club. All rights
          reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
