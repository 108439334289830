import React, {useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NAV_ITEMS } from "../constants/constant";
import Logo from "../assests/Logo-w-bg.webp";
import AboutUs from "./AboutUS";
import Services from "./Services";
import FacilitiesPage from "./Facilities";
import ContactUsPage from "./ContactUs";
import Footer from "./FooterComponent";
import SportsPage from "./Sports";
import PricingPage from "./Pricing";
import LogoScroller from "../components/LogoScroller";
import { Paper } from "@mui/material";

const Gallery = React.lazy(() => import("./PhotoGallery"));
function DrawerAppBar() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (item) => {
    if (item) {
      const sectionId = item.toLowerCase().replace(/\s+/g, "");
      window.location.hash = `#${sectionId}`;
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          const offset = 100;
          const topPosition =
            section.getBoundingClientRect().top + window.pageYOffset - offset;
          window.scrollTo({
            top: topPosition,
            behavior: "smooth",
          });
        }
      }, 100);
    }
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ backgroundColor: "white", padding: 0.5 }}>
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <img
                src={Logo}
                alt="Logo"
                height={100}
                width={120}
                style={{ borderRadius: "20px" }}
                loading="lazy"
              />
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontSize: { xs: "16px", sm: "20px" },
                  color: "#f1c22e",
                }}
              >
                Panchasheel Sports Club
              </Typography>
            </Box>

            <Box sx={{ display: { xs: "none", md: "block" }, fontWeight: 300 }}>
              {NAV_ITEMS.map((item) => (
                <Button
                  key={item}
                  sx={{
                    color: "#2d4583",
                    textTransform: "capitalize",
                    fontSize: "16px",
                  }}
                  onClick={() => handleMenuClose(item)}
                  className="on-hover-effect"
                >
                  {item}
                </Button>
              ))}
            </Box>

            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="open menu"
                edge="start"
                onClick={handleMenuClick}
                className="on-hover-effect"
              >
                <MenuIcon sx={{ color: "#2d4583" }} />
              </IconButton>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {NAV_ITEMS.map((item) => (
                <MenuItem
                  key={item}
                  onClick={() => handleMenuClose(item)}
                  sx={{ color: "#2d4583" }}
                  className="on-hover-effect"
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Toolbar />
        <Box id="aboutus">
          <AboutUs />
        </Box>
        <Box id="sports" width="100%">
          <SportsPage />
        </Box>
        <Box
          id="gallery"
          component={Paper}
          elevation={2}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: { sm: 2 },
          }}
        >
          <Gallery />
        </Box>
        <Box id="pricing" width="100%">
          <PricingPage />
        </Box>
        <div id="services">
          <Services />
        </div>
        <div id="facilities" width="100%">
          <FacilitiesPage />
        </div>
        <div id="contactus" width="100%">
          <ContactUsPage />
        </div>
        <Box width="100%">
          <LogoScroller />
        </Box>
        <Box id="footer" sx={{ width: "100%" }}>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
