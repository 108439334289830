export function validateForm(formData) {
    const errors = {};
  
    // Validate Name
    if (formData.name !== undefined) {
      if (!formData.name) {
        errors.name = "Name is required.";
      } else if (formData.name.trim().length < 2) {
        errors.name = "Name must be at least 2 characters long.";
      }
    }
  
    // Validate Email
    if (formData.email !== undefined) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!formData.email) {
        errors.email = "Email is required.";
      } else if (!emailRegex.test(formData.email)) {
        errors.email = "Invalid email address.";
      }
    }
  
    // Validate Phone Number
    if (formData.phoneNumber !== undefined) {
      const phoneRegex = /^[0-9]{10}$/;
      if (!formData.phoneNumber) {
        errors.phoneNumber = "Phone number is required.";
      } else if (!phoneRegex.test(formData.phoneNumber)) {
        errors.phoneNumber = "Phone number must be 10 digits.";
      }
    }
  
    // Validate Event Inquiry (Optional)
    if (formData.eventEnqury !== undefined) {
      if (formData.eventEnqury && formData.eventEnqury.trim().length < 5) {
        errors.eventEnqury = "Event Inquiry must be at least 5 characters long.";
      }
    }
  
    // Validate Message
    if (formData.message !== undefined) {
      if (!formData.message) {
        errors.message = "Message is required.";
      } else if (formData.message.trim().length < 10) {
        errors.message = "Message must be at least 10 characters long.";
      }
    }
  
    return errors;
  }
  