import React from "react";
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const PricingPage = () => {
  const notes = [
    "Cricket Bats, Stumps, Football and Bibs are complimentary.",
    "Badminton Rackets and Shoes are available for rent.",
    "Rescheduling is subject to availability.",
    "No refunds are applicable after booking.",
    "Energy Drinks, Cold Drinks and Mineral water are available in the office.",
  ];

  return (
    <Box
      sx={{
        fontFamily: "Roboto, sans-serif",
        padding: 2,
        mt: { sm: 8 },
      }}
    >
      {/* Header */}
      <Box sx={{ texhtAlign: "center", marginBottom: 2 }}>
        <Typography
          variant="h3"
          sx={{ color: "#2d4583" }}
          className="heading-color"
        >
          Pricing
        </Typography>
      </Box>

      {/* Main Content */}
      <Grid container spacing={4}>
        {/* Cricket/Football Turf Table */}
        <Grid item xs={12} md={4}>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", marginBottom: 2 }}
            className="heading-color"
          >
            Cricket / Football Turf
          </Typography>
          <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
            <Table>
              <TableHead sx={{ backgroundColor: "#2d4583" }}>
                <TableRow>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Time
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Mon-Fri
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Sat-Sun
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>6 AM to 9 AM</TableCell>
                  <TableCell>₹699/-</TableCell>
                  <TableCell>₹899/-</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>9 AM to 3 PM</TableCell>
                  <TableCell>₹399/-</TableCell>
                  <TableCell>₹599/-</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>3 PM to 7 PM</TableCell>
                  <TableCell>₹699/-</TableCell>
                  <TableCell>₹899/-</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>7 PM to 12 PM</TableCell>
                  <TableCell>₹799/-</TableCell>
                  <TableCell>₹999/-</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Badminton Table */}
        <Grid item xs={12} md={4}>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", marginBottom: 2 }}
            className="heading-color"
          >
            Badminton Monthly Pass
          </Typography>
          <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
            <Table>
              <TableHead sx={{ backgroundColor: "#2d4583" }}>
                <TableRow>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Time
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Price
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ fontWeight: 400 }}>
                  <TableCell>6 AM to 11 AM</TableCell>
                  <TableCell>₹250/- per hour</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>11 AM to 4 PM</TableCell>
                  <TableCell>₹200/- per hour</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>4 PM to 12 PM</TableCell>
                  <TableCell>₹250/- per hour</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "#2d4583",
                        fontWeight: "bold",
                      }}
                    >
                      ₹5000/- for 30 hours!
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", marginBottom: 2 }}
              className="heading-color"
            >
              Discounts
            </Typography>
            <Box
              sx={{
                // backgroundColor: "white",
                // color: "#2d4583",
                padding: 0.3,
                borderRadius: "8px",
                textAlign: "center",
                // marginTop: 6,
              }}
            >
              <TableContainer
                component={Paper}
                elevation={4}
                sx={{ backgroundColor: "#2d4583", color: "white" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="discounts table">
                  <TableBody>
                    {/* Row for Student Discounts */}
                    <TableRow>
                      <TableCell sx={{ width: "50%", padding: "16px" }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            marginBottom: 1,
                            color: "white",
                          }}
                        >
                          Discounts for Students!
                        </Typography>
                        <Typography sx={{ color: "white" }}>
                          <strong className="heading-color">
                            10% (Badminton)
                          </strong>{" "}
                          6:00AM To 6:00PM
                          <br />
                        </Typography>
                        <Typography sx={{ marginTop: 1, color: "white" }}>
                          (Only with College ID)
                        </Typography>
                      </TableCell>
                      {/* Empty cell for alignment */}
                      <TableCell sx={{ width: "50%" }} />
                    </TableRow>
                    {/* Row for Turf Discounts */}
                    <TableRow>
                      <TableCell sx={{ width: "50%", padding: "16px" }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            marginBottom: 1,
                            color: "white",
                          }}
                        >
                          Discounts for Turf!
                        </Typography>
                        <Typography sx={{ color: "white" }}>
                          <strong>10%</strong> off for 20+ hours <br />
                          <strong>15%</strong> off for 30+ hours <br />
                          <strong>20%</strong> off for 40+ hours
                        </Typography>
                      </TableCell>
                      {/* Empty cell for alignment */}
                      <TableCell sx={{ width: "50%" }} />
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          {/* Discount Section */}
          {/* <Typography
            variant="h5"
            sx={{ fontWeight: "bold", textAlign: "center", marginBottom: 2 }}
            className="heading-color"
          >
            Discounts
          </Typography>
          <Box
            sx={{
              backgroundColor: "#2d4583",
              color: "white",
              padding: 3,
              borderRadius: "8px",
              textAlign: "center",
              marginTop: 6,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", marginBottom: 1 }}
                >
                  Discounts for Students!
                </Typography>
                <Typography>
                  <strong className="heading-color">10% (Badminton)</strong>{" "}
                  6:00AM To 6:00PM
                  <br />
                </Typography>
                <Typography sx={{ marginTop: 1 }}>
                  (Valid only with College ID)
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", marginBottom: 1 }}
                >
                  Discounts for Turf!
                </Typography>
                <Typography>
                  <strong>10%</strong> off for 20+ hours <br />
                  <strong>15%</strong> off for 30+ hours <br />
                  <strong>20%</strong> off for 40+ hours
                </Typography>
              </Grid>
            </Grid>
          </Box> */}
        </Grid>
      </Grid>

      {/* Notes Section */}
      <Box
        sx={{
          marginTop: 6,
          maxWidth: "800px",
          marginX: "auto", // Center alignment
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textAlign: "left", marginBottom: 2 }}
          className="text-color"
        >
          Notes:
        </Typography>
        <List
          sx={{
            backgroundColor: "#f9f9f9",
            padding: 2,
            borderRadius: 2,
            border: "1px solid #ddd",
            boxShadow: 3,
          }}
        >
          {notes.map((note, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{
                borderBottom:
                  index !== notes.length - 1 ? "1px solid #ddd" : "none",
                padding: "10px 0",
              }}
            >
              <ListItemText
                primary={`${index + 1}. ${note}`}
                primaryTypographyProps={{
                  variant: "body1",
                  sx: {
                    color: "#2d4583",
                    fontSize: "16px",
                    textIndent: "1.5px",
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default PricingPage;
