import React from "react";
import { Container, Box, Typography, Grid } from "@mui/material";
import Amenities from "../assests/facilitiesicons/amenities.webp";
import Cricket from "../assests/facilitiesicons/Cricket.webp";
import Running from "../assests/facilitiesicons/running (1).webp";
import Football from "../assests/facilitiesicons/shoot.webp";
import CricketTurf from "../assests/facilitiesicons/pitch (1).webp";
import Fans from "../assests/facilitiesicons/fans.webp";
import Sport from "../assests/facilitiesicons/sport.webp";
import SportsEquvipment from "../assests/facilitiesicons/sportsequvipments.webp";
import PanchasheelSmall from "../assests/cardImages/punchsheel_small.webp";
import PanchasheelMedium from "../assests/cardImages/punchsheel_medium.webp";
import PanchasheelLarge from "../assests/cardImages/punchsheel_large.webp";

const AboutUs = () => {
  return (
    <Container maxWidth="100%">
      {/* <Box
        sx={{
          backgroundImage: {
            xs: `url(${PanchasheelSmall})`,
            md: `url(${PanchasheelMedium})`,
            lg: `url(${PanchasheelLarge})`,
          },
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: { xs: "40vh", sm: "50vh" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: { xs: "row", sm: "column" },
          color: "white",
          textAlign: "center",
          mt: 8,
          mb: 2,
          borderRadius: "20px",
        }}
      >
        <Typography
          variant="h3"
          fontWeight="bold"
          color="white"
          mt={{ xs: 0, sm: 18 }}
        >
          Welcome to the Ultimate Sports Club
        </Typography>
        <Typography variant="h5" sx={{ mt: { sm: 2 } }}>
          Your destination for excellence in sports and fitness
        </Typography>
      </Box> */}
      <Box
        sx={{
          position: "relative", // Required for absolute positioning of child elements
          height: { xs: "40vh", sm: "50vh" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: { xs: "row", sm: "column" },
          color: "white",
          textAlign: "center",
          mt: 8,
          mb: 2,
          borderRadius: "20px",
          overflow: "hidden", // Ensures content stays within the border radius
        }}
      >
        {/* Responsive image */}
        <Box
          component="img"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          src={PanchasheelSmall}
          srcSet={`${PanchasheelSmall} 600w, ${PanchasheelMedium} 1200w, ${PanchasheelLarge} 1920w`}
          sizes="(max-width: 600px) 600px, 
               (max-width: 1200px) 1200px, 
               1920px"
          alt="Panchasheel Sports Club"
          loading="lazy"
        />

        {/* Overlay text */}
        <Box
          sx={{
            position: "relative", // Keeps the text above the image
            zIndex: 1,
            textShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)", // Improves text readability
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            mt={{ xs: 0, sm: 18 }}
            sx={{ color: "white" }}
          >
            Welcome to the Ultimate Sports Club
          </Typography>
          <Typography variant="h5" sx={{ mt: { sm: 2 } }}>
            Your destination for excellence in sports and fitness
          </Typography>
        </Box>
      </Box>
      <Box sx={{ borderRadius: 2 }} p={1} className="cards-border">
        <Typography variant="h3" align="center" color="#f1c22e">
          About Us
        </Typography>
        <Box sx={{ py: 4 }}>
          <Typography variant="h6" paragraph textAlign="start" color="#2d4583">
            Welcome to{" "}
            <strong className="heading-color">Panchasheel Sports Club</strong>,
            the premier sports venue dedicated to providing a world-class
            experience for athletes, sports fans, and event organizers. Located
            in <strong className="heading-color">Pune</strong>, our
            state-of-the-art facility offers a variety of sporting spaces,
            training opportunities, and event hosting services, making us the
            go-to destination for both competitive and recreational sports.
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
        }}
        gap={1}
      >
        <Grid
          item
          xs={12}
          sm={5.9}
          sx={{ p: 4, borderRadius: 2 }}
          className="cards-border"
        >
          <Typography variant="h4" gutterBottom className="heading-color">
            Our Story
          </Typography>
          <Typography paragraph textAlign="start" className="text-color">
            Established in <strong className="heading-color">2023</strong>,{" "}
            <strong className="heading-color">Panchasheel Sports Club</strong>{" "}
            was built with one goal in mind: to create a facility that promotes
            health, wellness, and community through sports. Over the years, we
            have become a cornerstone of the local sports scene, hosting
            everything from regional tournaments and professional games to
            community fitness programs and corporate events.
          </Typography>
          <Typography paragraph textAlign="start" className="text-color">
            We are proud to serve athletes of all ages and skill levels, from
            budding youth athletes to seasoned professionals. With a strong
            focus on accessibility, inclusivity, and safety,{" "}
            <strong className="heading-color">Panchasheel Sports Club</strong>{" "}
            is committed to providing a space where people can come together to
            compete, train, or simply enjoy the game.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5.9}
          sx={{ p: 4, borderRadius: 2 }}
          className="cards-border"
        >
          <Typography variant="h4" gutterBottom className="heading-color">
            Our Mission
          </Typography>
          <Typography paragraph textAlign="start" className="text-color">
            At{" "}
            <strong className="heading-color">Panchasheel Sports Club</strong>,
            our mission is to deliver top-tier sports facilities and exceptional
            service that enrich the local community and inspire the athletes of
            tomorrow. We strive to create a dynamic and inclusive environment
            where sportsmanship, teamwork, and personal growth thrive.
          </Typography>
          <Typography paragraph textAlign="start" className="text-color">
            We are dedicated to supporting local sports teams, schools, and
            organizations, while also hosting large-scale events that bring
            people together to celebrate the power of sports. Whether you're
            here to compete, train, or spectate, we aim to provide a memorable
            experience for every guest.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5.9}
          sx={{ p: 4, borderRadius: 2 }}
          className="cards-border"
        >
          <Typography variant="h4" gutterBottom className="heading-color">
            Our Facilities
          </Typography>
          <Typography paragraph textAlign="start" className="text-color">
            <strong className="heading-color">Panchasheel Sports Club</strong>{" "}
            offers a wide range of facilities designed to accommodate a variety
            of sports and events, including:
          </Typography>

          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              gap={1}
              className="text-color d-flex-align-justify-center"
            >
              <img src={Football} alt="Football" height="40px" width="40px" />{" "}
              <Typography variant="body1">Football Astro Turf </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              gap={1}
              className="text-color d-flex-align-justify-center"
            >
              <img src={Sport} alt="badminton" height="40px" width="40px" />{" "}
              <Typography variant="body1">Badminton Courts</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              gap={1}
              className="text-color text-color d-flex-align-justify-center"
            >
              <img
                src={CricketTurf}
                alt="cricket-pitch"
                height="40px"
                width="40px"
              />
              <Typography variant="body1">Cricket Truf </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              gap={1}
              className="text-color text-color d-flex-align-justify-center"
            >
              <img
                src={Cricket}
                alt="cricket-nets"
                height="40px"
                width="40px"
              />{" "}
              <Typography variant="body1">Cricket Net Practice</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              gap={1}
              className="text-color text-color d-flex-align-justify-center"
            >
              <img
                src={SportsEquvipment}
                alt="Equvipments"
                height="40px"
                width="40px"
              />{" "}
              <Typography variant="body1">
                State-of-the-Art Equipment for Rent
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              gap={1}
              className="text-color text-color d-flex-align-justify-center"
            >
              <img src={Running} alt="fitness" height="40px" width="40px" />{" "}
              <Typography variant="body1">
                Training and Fitness Spaces
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              gap={1}
              className="text-color text-color d-flex-align-justify-center"
            >
              <img src={Fans} alt="fans" height="40px" width="40px" />
              <Typography variant="body1">Spectator Areas</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              gap={1}
              className="text-color text-color d-flex-align-justify-center"
            >
              <img src={Amenities} alt="Amenities" height="40px" width="40px" />{" "}
              <Typography variant="body1">
                Accessible and Family-Friendly Amenities
              </Typography>
            </Grid>
          </Grid>

          <Typography
            paragraph
            textAlign="start"
            sx={{ mt: 2 }}
            className="text-color"
          >
            We continually update and improve our venue to ensure that it
            remains a safe, cutting-edge space for all types of events and
            activities.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={5.9}
          sx={{ p: 4, borderRadius: 2 }}
          className="cards-border"
        >
          <Typography variant="h4" gutterBottom className="heading-color">
            Why Choose Us?
          </Typography>
          <Box
            component="ul"
            sx={{ pl: 2, textAlign: "start" }}
            className="text-color"
          >
            <Typography component="li" paragraph>
              <strong>Experience:</strong> With over 2 years in the industry, we
              have a proven track record of successfully hosting sports events
              and providing top-tier facilities.
            </Typography>
            <Typography component="li" paragraph>
              <strong>Professional Staff:</strong> Our team is made up of
              experienced professionals dedicated to ensuring your event or
              training session runs smoothly.
            </Typography>
            <Typography component="li" paragraph>
              <strong>Flexibility:</strong> Whether you're hosting a major
              tournament or just looking to rent a court for a friendly game, we
              offer a variety of rental options to fit your needs.
            </Typography>
            <Typography component="li" paragraph>
              <strong>Community Focus:</strong> We believe in the power of
              sports to bring people together, which is why we’re committed to
              supporting local sports teams, schools, and community initiatives.
            </Typography>
          </Box>
          <Typography paragraph textAlign="start" className="text-color">
            We look forward to welcoming you to{" "}
            <strong className="heading-color">Panchasheel sports club</strong>{" "}
            and providing a space where athletes can excel, fans can cheer, and
            communities can come together to celebrate the joy of sport.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5.9}
          sx={{ p: 4, borderRadius: 2 }}
          className="cards-border"
        >
          <Typography variant="h4" gutterBottom className="heading-color">
            Our Vision
          </Typography>
          <Typography paragraph textAlign="start" className="text-color">
            To be recognized as the leading sports venue in{" "}
            <strong className="heading-color">Pune</strong>, where athletes,
            sports enthusiasts, and communities come to connect, grow, and
            achieve excellence. We envision a facility that not only hosts major
            sporting events but also serves as a hub for education, wellness,
            and youth development.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={5.9}
          sx={{ p: 4, borderRadius: 2 }}
          className="cards-border"
        >
          <Typography variant="h4" gutterBottom className="heading-color">
            Contact Us{" "}
          </Typography>
          <Typography paragraph textAlign="start" className="text-color">
            Have questions or want to learn more about booking our venue? Visit
            our{" "}
            <srtong className="heading-color" style={{ cursor: "pointer" }}>
              Contact Us
            </srtong>{" "}
            page or reach out to us at{" "}
            <strong className="heading-color">
              <a
                href="mailto:panchasheelsportsclub@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                className="link-styles"
              >
                panchasheelsportsclub@gmail.com
              </a>
            </strong>
            .
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutUs;
