import React, { Component } from "react";
import { Button, Box, Typography } from "@mui/material";
import Logo from "../assests/Logo-w-bg.webp";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    // Optionally log error to an error tracking service here
    console.error("Error caught in Error Boundary:", error, errorInfo);
  }

  handleNavigate = (hash) => {
    window.location.hash = hash; // Use hash-based navigation to navigate
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f4eae6",
            textAlign: "center",
            padding: 3,
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            height={120}
            width={180}
            style={{ marginBottom: "20px", borderRadius: "10px" }}
          />
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", color: "#2d4583", marginBottom: 2 }}
          >
            Oops! Something went wrong.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#2d4583", marginBottom: 3 }}
          >
            We're sorry, an unexpected error has occurred. Please try again
            later.
          </Typography>
          <Button
            variant="contained"
            color=""
            sx={{ backgroundColor: "#2d4583", color: "#fff" }}
            onClick={() => this.handleNavigate("#aboutus")}
          >
            Go to Home
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
